import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BriefingCompanyData } from '_components/Briefing/types';
import { SnackBar } from '_components/Briefing/versions/NormalBriefing/Body/SnackBar';
import { CompanyAutocomplete } from '_components/form/CompanyAutocomplete';
import { NestedError } from '_types/generic';

import { ErrorLabel } from '../../components/ErrorLabel';
import { QuestionStructure } from '../../components/QuestionStructure';
import { BriefingQuestionProps } from '../types';
import { defaultStyles } from '../utils';
import { getCompanyLabel, hasCompanyInfo } from './utils';

export const CompanyQuestion = (props: BriefingQuestionProps) => {
  const { t } = useTranslation(['briefing']);
  const {
    bodyClass = defaultStyles['bodyClass'],
    className,
    isAutoFocus,
    editing = true,
    edit,
    cancelEdit,
    name = 'company',
    title = t('briefing:company.question'),
    subtitle,
    titleClass = defaultStyles['titleClass'],
    topSnackBar,
    showConfidential = false,
    hideWebsite = false,
    selectOnClickOutside = true,
    minHeight,
    isMandatory,
  } = props;
  const { control, trigger } = useFormContext();

  return (
    <div className={className} data-testid={`${name}-question-answer`}>
      {topSnackBar ? topSnackBar : null}

      <div className={bodyClass}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <QuestionStructure
                name={name}
                title={title}
                titleClass={titleClass}
                subtitle={subtitle}
                isMandatory={isMandatory}
                children={
                  <Fragment>
                    <CompanyAutocomplete
                      onChange={(company?: BriefingCompanyData) => {
                        onChange(company);
                        trigger(name);
                      }}
                      currentValue={value}
                      isAutoFocus={isAutoFocus}
                      hideWebsiteQuestion={hideWebsite}
                      selectOnClickOutside={selectOnClickOutside}
                      minHeight={minHeight}
                    />
                    {!topSnackBar && (
                      <ErrorLabel message={(error as NestedError<BriefingCompanyData>)?.name?.message} />
                    )}
                  </Fragment>
                }
                answer={hasCompanyInfo(value) ? getCompanyLabel(value) : undefined}
                editing={editing}
                edit={edit}
                cancelEdit={cancelEdit}
              />
            );
          }}
        />

        {editing && showConfidential ? (
          <div className="layout-wrap">
            <SnackBar
              className="mt-32 px-16 py-8 rounded-sm"
              type="confidential"
              message={t('briefing:company.confidential_v3')}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
