import { useQuery } from '@tanstack/react-query';

import type { BriefingCompanyData } from '_components/Briefing/types';
import { api as clearbitApi } from '_core/api/clearbit-api';

export type Company = BriefingCompanyData;

export const cacheKey = {
  autocomplete: (query: string) => `/v1/companies/suggest?query=${query}`,
};

export const urls = {
  autocomplete: (query: string) => `/v1/companies/suggest?query=${query}`,
};

export const clearbitRepo = {
  autocomplete: (params: { query: string }): Promise<Company[]> => {
    return clearbitApi.get(`${urls.autocomplete(params.query)}`).then((data) => data.data);
  },
};

export function useAutocomplete({ query }: { query: string }) {
  return useQuery({
    queryKey: [cacheKey.autocomplete(query)],
    queryFn: () => {
      return clearbitRepo.autocomplete({ query });
    },
    enabled: false,
  });
}
