import { Textarea } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyString } from '@sortlist-frontend/utils';
import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorLabel } from '../../components/ErrorLabel';
import { QuestionStructure } from '../../components/QuestionStructure';
import { BriefingQuestionProps } from '../types';
import { defaultStyles } from '../utils';

export const TextAreaQuestion = (props: BriefingQuestionProps) => {
  const { t } = useTranslation(['common', 'briefing']);
  const {
    bodyClass = defaultStyles['bodyClass'],
    label,
    name = 'extraComment',
    title = t('briefing:extraComment.question_v2'),
    placeholder = t('common:briefing.enterText'),
    className,
    editing = true,
    edit,
    cancelEdit,
    titleClass = defaultStyles['titleClass'],
    minLength,
    showCounter = false,
    showErrorOnSubmit = false,
    subtitle,
    rows = 6,
    isMandatory,
  } = props;
  const { control, trigger } = useFormContext();

  return (
    <div className={className} data-testid={`${name}-question-answer`}>
      <div className={bodyClass}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange }, fieldState: { error, isDirty, isValidating } }) => {
            const showError = error?.message != null && (showErrorOnSubmit || isDirty);

            return (
              <QuestionStructure
                name={name}
                title={title}
                titleClass={titleClass}
                subtitle={subtitle}
                isMandatory={isMandatory}
                children={
                  <Fragment>
                    <Textarea
                      placeholder={placeholder}
                      label={isNonEmptyString(label) ? label : undefined}
                      rows={rows}
                      name={name}
                      onChange={(ev) => {
                        onChange(ev.target.value ?? '');
                        if (error != null) trigger(name);
                      }}
                      value={value}
                      resize="vertical"
                      {...(minLength != null && { minLength })}
                      error={!!showError && !isValidating}
                    />

                    {(showError || showCounter) && !isValidating && (
                      <div className="layout-row layout-align-space-between">
                        {showError && <ErrorLabel message={error?.message} />}
                        {showCounter && <p className="flex text-right">{value?.length ?? 0}</p>}
                      </div>
                    )}
                  </Fragment>
                }
                answer={isNonEmptyString(value) ? value : undefined}
                editing={editing}
                edit={edit}
                cancelEdit={cancelEdit}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
