import { cx } from '@emotion/css';
import { CheckRounded, InfoTwoTone } from '@mui/icons-material';
import { Tooltip } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { briefingConfig } from '_features/briefing/briefing.config';

type ContactInfoSavedProps = {
  className?: string;
};

export const ContactInfoSaved = (props: ContactInfoSavedProps) => {
  const { className } = props;
  const { t } = useTranslation(briefingConfig.i18nNamespaces);

  return (
    <Tooltip title={t('briefing:direct.infoSavedTooltip')}>
      <div
        className={cx(
          className,
          'layout-row layout-align-center-center bg-success-100 border border-success-300 rounded-sm px-16 py-8',
        )}>
        <CheckRounded className="text-success-500" fontSize="inherit" />
        <p className="flex text-success-500 p-8">{t('briefing:direct.contactInfoSaved')}</p>
        <InfoTwoTone className="text-success-500" fontSize="inherit" />
      </div>
    </Tooltip>
  );
};
