import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { Fragment } from 'react';

import { briefingConfig } from '_features/briefing/briefing.config';
import { VisibilityProjectProps } from '_pages/api/proxy/project/[encodedProjectUuid]/find-visibility-project';

type ContactDetailsProps = {
  project: VisibilityProjectProps;
  startEditing: () => void;
};

export const ContactDetails = (props: ContactDetailsProps) => {
  const { project, startEditing } = props;
  const { members, briefing } = project;
  const { name: companyName } = briefing?.company ?? {};
  const { firstName, lastName, email, phone } = members?.nodes?.[0];
  const { t } = useTranslation(briefingConfig.i18nNamespaces);

  return (
    <Fragment>
      <p className="mt-32 bold">{t('briefing:direct.whoAreYou')}</p>
      <div className="mt-16 p-12 border border-secondary-300 rounded-md bg-secondary-100">
        <p className="medium">{`${firstName} ${lastName}`}</p>
        {companyName != null && <p className="small text-secondary-500">{companyName}</p>}
        <div className="mt-12">
          {phone != null && <p className="small text-secondary-500">{phone}</p>}
          <p className="small text-secondary-500">{email}</p>
        </div>
        <Button
          size="xs"
          className="mt-16"
          buttonVariant="outlined"
          buttonStyle="secondary"
          data-testid="edit-btn"
          animation="none"
          onClick={startEditing}
          label={t('briefing:direct.edit')}
        />
      </div>
    </Fragment>
  );
};
