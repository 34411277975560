import { TFunction } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyPlainObject } from '@sortlist-frontend/utils';

import { getOptions } from '_components/form/PersonaSelector/utils';
import type { FreeTextOption } from '_types/generic';

export const getPersonaLabel = (t: TFunction, persona?: FreeTextOption): FreeTextOption['value'] | undefined => {
  if (persona?.tag === 'other') return persona?.value;
  const options = getOptions(t);
  const option = options.find(({ value }) => value === persona?.tag);
  return isNonEmptyPlainObject(option) ? option.label : undefined;
};
