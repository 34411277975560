import { Fragment } from 'react';

import { cx } from '@emotion/css';

type Props = {
  message?: string;
  className?: string;
};

export const ErrorLabel = (props: Props) => {
  const { message, className } = props;
  if (!message || message.length === 0) return <Fragment></Fragment>;
  return (
    <div className={cx(className)}>
      <p className="text-error py-4 px-8 small display-inline-block">{message}</p>
    </div>
  );
};
