import { Avatar } from '@sortlist-frontend/design-system';
import { isNonEmptyPlainObject } from '@sortlist-frontend/utils';

import { BriefingCompanyData } from '_components/Briefing/types';

const getCompanytext = (name?: string, domain?: string) => {
  return name && domain ? `${name} (${domain})` : name || '-';
};

export const getCompanyLabel = (value?: BriefingCompanyData) => {
  const { name, domain, logo } = value ?? {};
  return (
    <span className="layout-row layout-align-start-center">
      {logo && <Avatar src={logo} size="xs" alt={name} fit="contain" shape="square" className="mr-8" />}
      <>{getCompanytext(name, domain)}</>
    </span>
  );
};

export const hasCompanyInfo = (value: BriefingCompanyData): boolean => {
  return isNonEmptyPlainObject(value) && !!(value.name || value.domain);
};
