import { HttpException } from '@sortlist-frontend/utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';
import { captureGqlError } from '_core/error/capture-gql-error';
import { MessageResponse } from '_pages/api/proxy/project/[encodedProjectUuid]/message';

import { MessageBriefingArgs } from './types';

const urls = {
  createMessage: (encodedProjectUuid: string) => `/api/proxy/project/${encodedProjectUuid}/message`,
};

export const projectRepo = {
  createMessage: async (
    briefingArgs: MessageBriefingArgs,
    encodedProjectUuid: string,
    config?: AxiosRequestConfig,
  ): Promise<MessageResponse> => {
    const returnObject = await api.post(urls.createMessage(encodedProjectUuid), briefingArgs, config);
    return returnObject?.data?.data;
  },
};

export function useCreateMessage(encodedProjectUuid: string) {
  const { domainInfo } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';

  return useMutation({
    mutationFn: (briefingArgs: MessageBriefingArgs) => {
      return projectRepo.createMessage(briefingArgs, encodedProjectUuid, { baseURL });
    },
    onError: (e: HttpException) => captureGqlError(e),
  });
}
