import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { HttpException, isNonEmptyPlainObject } from '@sortlist-frontend/utils';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ErrorLabel } from '_components/Briefing/components/ErrorLabel';
import { TextAreaQuestion } from '_components/Briefing/questions/TextAreaQuestion';
import { getBackendErrorMessage } from '_components/Briefing/utils';
import { MessageResponse } from '_pages/api/proxy/project/[encodedProjectUuid]/message';

import { ContactDetails } from './ContactDetails';
import { ContactInfoSaved } from './ContactInfoSaved';
import { useCreateMessage } from './message-briefing.repo';
import { MessageBriefingForm, MessageBriefingProps } from './types';
import { getVariables } from './utils';
import { getSchema } from './validation';

export const MessageBriefing = (props: MessageBriefingProps) => {
  const { title, agencySlug, existingProject, callback, setUrlRedirection, project, setIsEditing } = props;
  const { encodedProjectUuid, message = '' } = existingProject;
  const { t } = useTranslation(['common', 'briefing']);
  const validationSchema = getSchema();

  const [isPosting, setIsPosting] = useState<boolean>(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState<string | undefined>(undefined);

  const { mutate: create, isPending: isLoading } = useCreateMessage(encodedProjectUuid);

  const { ...methods } = useForm<MessageBriefingForm>({
    defaultValues: { extraComment: message },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
  });

  const { watch, trigger, formState } = methods;
  const { errors } = formState;
  const { extraComment }: MessageBriefingForm = watch();

  const onSuccess = (data: MessageResponse) => {
    setBackendErrorMessage(undefined);
    const { claimUrl } = data?.createNomineeMessage?.sender ?? {};
    setUrlRedirection != null && setUrlRedirection(claimUrl);
    callback != null && callback();
  };

  const handleOnError = (error: HttpException) => {
    setBackendErrorMessage(getBackendErrorMessage(error, t));
    setIsPosting(false);
  };

  const handleCreate = () => {
    setIsPosting(true);
    trigger().then((isValid) => {
      if (!isValid) return setIsPosting(false);

      const variables = getVariables(extraComment, agencySlug, existingProject);
      isNonEmptyPlainObject(variables) &&
        create(variables, { onSuccess, onError: (e: HttpException) => handleOnError(e) });
    });
  };

  const isBusy = isPosting || isLoading;

  return (
    <FormProvider {...methods}>
      <form className="layout-column flex">
        <div className="layout-column p-24">
          {title && <p className="bold h1">{title}</p>}
          {project != null && setIsEditing != null ? (
            <ContactDetails startEditing={() => setIsEditing(true)} project={project} />
          ) : (
            <ContactInfoSaved className="mt-24" />
          )}
          <p className="mt-32 bold">{t('briefing:direct.whatMessage_1')}</p>
          <span className="mt-16 small medium">{t('briefing:direct.yourMessage')}</span>
          <TextAreaQuestion
            bodyClass="p-0"
            className="mt-4"
            titleClass="hide"
            placeholder={t('common:briefing.typeYourMessage')}
          />
        </div>

        <div className="layout-align-gt-xs-start-stretch layout-column pb-12 px-16">
          {backendErrorMessage && <ErrorLabel className="flex p-8" message={backendErrorMessage} />}
          <div className="p-8 layout-align-end-center layout-row">
            <Button
              size="md"
              buttonStyle="primary"
              buttonVariant="raised"
              label={t('briefing:direct.send')}
              animation="ripple"
              iconRight={<ArrowForwardRounded />}
              type="button"
              loading={isBusy}
              disabled={isBusy}
              onClick={handleCreate}
              id="send-direct-briefing-btn"
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
