import { cx } from '@emotion/css';
import { EmojiObjectsTwoTone, InfoTwoTone, LockTwoTone, WarningTwoTone } from '@mui/icons-material';
import { ReactNode, useEffect, useState } from 'react';

type SnackBarProps = {
  className?: string;
  type?: 'confidential' | 'error' | 'tip';
  message: any;
};

export const SnackBar = (props: SnackBarProps) => {
  const { className, message, type } = { ...props };
  const [icon, setIcon] = useState<ReactNode | undefined>(undefined);
  const [colorClasses, setColorClasses] = useState<string | undefined>(undefined);

  const getColors = (type: SnackBarProps['type']) => {
    switch (type) {
      case 'tip':
        setColorClasses('text-success-700 bg-success-200');
        setIcon(<EmojiObjectsTwoTone className="mr-8 text-success-700" style={{ fontSize: 16 }} />);
        break;
      case 'confidential':
        setColorClasses('text-secondary-700 bg-secondary-200');
        setIcon(<LockTwoTone className="mr-8 text-secondary-700" style={{ fontSize: 16 }} />);
        break;
      case 'error':
        setColorClasses('text-danger-700 bg-danger-200');
        setIcon(<WarningTwoTone className="mr-8 text-danger-700" style={{ fontSize: 16 }} />);
        break;
      default:
        setColorClasses('text-primary-500 bg-primary-200');
        setIcon(<InfoTwoTone className="mr-8 text-primary-700" style={{ fontSize: 16 }} />);
        break;
    }
  };

  useEffect(() => {
    getColors(type);
  }, []);

  return !!message ? (
    <div className={cx(className, colorClasses, 'layout-row layout-align-center-center')}>
      {icon}
      <p className="small lh-1">{message}</p>
    </div>
  ) : null;
};
