import { mapErrorToHttpException } from '@sortlist-frontend/utils';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

// Create new instance and configue
export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CLEARBIT_API_BASE_URL as string,
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    throw mapErrorToHttpException({
      error,
      url: error.request?.res?.responseUrl,
      errorPayload: error.config.data,
      service: 'Clearbit API',
    });
  },
);

export const api = {
  get: (url: string, config?: AxiosRequestConfig) => axiosInstance.get(url, config),
  post: (url: string, data: unknown, config?: AxiosRequestConfig) => axiosInstance.post(url, data, config),
  put: (url: string, data: unknown, config?: AxiosRequestConfig) => axiosInstance.put(url, data, config),
  delete: (url: string, config?: AxiosRequestConfig) => axiosInstance.delete(url, config),
  patch: (url: string, data: unknown, config?: AxiosRequestConfig) => axiosInstance.patch(url, data, config),
  head: (url: string) => axiosInstance.head(url),
};
