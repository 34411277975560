import { AddCircleOutlineRounded } from '@mui/icons-material';
import { Avatar, Input, MenuItem, Select } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyArray, useDebounceCallback } from '@sortlist-frontend/utils';
import { Fragment, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { components, MenuListProps, OptionProps } from 'react-select';

import type { BriefingCompanyData } from '_components/Briefing/types';
import { useAutocomplete } from '_core/repos/clearbit.repo';

type CompanyAutocompleteProps = {
  className?: string;
  onChange: (company?: BriefingCompanyData) => void;
  hideWebsiteQuestion?: boolean;
  currentValue?: BriefingCompanyData;
  isAutoFocus?: boolean;
  selectOnClickOutside?: boolean;
  minHeight?: boolean;
};

export const CompanyAutocomplete = (props: CompanyAutocompleteProps) => {
  const {
    className,
    currentValue,
    onChange,
    isAutoFocus = true,
    hideWebsiteQuestion = false,
    selectOnClickOutside = true,
    minHeight = true,
  } = props;
  const { t } = useTranslation(['common']);
  const isNewCompany = Boolean(currentValue?.name && !currentValue?.logo);

  const reactSelectRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>();
  const [selectedCompany, setSelectedCompany] = useState<BriefingCompanyData | undefined>(currentValue);
  const [showDomain, setShowDomain] = useState<boolean>(isNewCompany);

  const { data: companiesOptions, refetch, ...rest } = useAutocomplete({ query: inputValue as string });
  const debouncedFetch = useCallback(useDebounceCallback(refetch, 300), []);

  useEffect(() => {
    inputValue && debouncedFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const newCompany: BriefingCompanyData | undefined = inputValue ? { name: inputValue } : undefined;

  const handleChange = (company?: BriefingCompanyData, allowSetDomain = true) => {
    if (company == null) {
      setSelectedCompany(undefined);
      onChange(undefined);
      setShowDomain(false);
      return;
    }

    setSelectedCompany(company);
    onChange(company);
    setMenuOpen(false);
    allowSetDomain && setShowDomain(!company.domain);
  };

  const MenuList = (props: MenuListProps<BriefingCompanyData, false>) => {
    return (
      <components.MenuList {...props}>
        <div>{props.children}</div>
        <MenuItem onClick={() => handleChange(newCompany)}>
          <AddCircleOutlineRounded fontSize="inherit" className="mr-8" />
          <span>{inputValue}</span>
        </MenuItem>
      </components.MenuList>
    );
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const firstOption = isNonEmptyArray(companiesOptions) ? companiesOptions[0] : newCompany;
      firstOption?.name && handleChange(firstOption);
    }
  };

  const handleInputChange = (searchValue: string) => {
    setInputValue(searchValue);
    if (searchValue) setMenuOpen(true);
  };

  const handleFocus = () => {
    setInputValue(selectedCompany?.name);
  };

  const Option = (props: OptionProps<BriefingCompanyData, false>) => {
    return (
      <components.Option {...props}>
        <div className="layout-row layout-align-start-center cursor-pointer">
          <Avatar src={props.data.logo} alt={props.data.name} size="sm" fit="contain" shape="square" />
          <div className="layout-column flex pl-8">
            <div className="mr-8">{props.data.name}</div>
            {hideWebsiteQuestion ? null : (
              <i>
                <small className="text-secondary-700">{props.data.domain}</small>
              </i>
            )}
          </div>
        </div>
      </components.Option>
    );
  };

  return (
    <div className={className} data-testid="company-autocomplete" ref={reactSelectRef}>
      <Select
        instanceId={'react-select-company-autocomplete'}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={isAutoFocus}
        menuIsOpen={menuOpen}
        backspaceRemovesValue={false}
        placeholder={t('common:companyAutocomplete.placeholder')}
        options={companiesOptions}
        value={selectedCompany}
        isClearable={true}
        styles={{
          control: (defaults) => ({
            ...defaults,
            ...(minHeight ? { minHeight: '2.75rem !important' } : {}),
          }),
          container: (defaults) => ({
            ...defaults,
            width: '100%',
          }),
          valueContainer: (defaults) => ({
            ...defaults,
            cursor: 'text',
          }),
        }}
        onInputChange={handleInputChange}
        onChange={(company) => handleChange(company as BriefingCompanyData)}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        getOptionLabel={(option) => (option as BriefingCompanyData).name as string}
        getOptionValue={(option) => option as string}
        components={{
          ...(!inputValue ? { Menu: () => null } : {}),
          MenuList: (props) => <MenuList {...(props as MenuListProps<BriefingCompanyData, false>)} />,
          Option: (props) => <Option {...(props as OptionProps<BriefingCompanyData, false>)} />,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage: () => null,
        }}
      />

      {showDomain && !hideWebsiteQuestion && (
        <Fragment>
          <p className="mt-16 mb-4">{t('common:briefing.websiteQuestion')}</p>
          <Input
            height="sm"
            onChange={(ev) => {
              handleChange({ ...selectedCompany, domain: ev.target.value }, false);
            }}
            autoFocus={!selectedCompany?.domain} // eslint-disable-line jsx-a11y/no-autofocus
            value={selectedCompany?.domain}
            defaultValue="https://www."
            type="text"
          />
        </Fragment>
      )}
    </div>
  );
};
