import { getStorage, isNonEmptyString, removeStorage, setStorage } from '@sortlist-frontend/utils';

import { ExistingProject, MessageBriefingArgs } from './types';

export const EXISTING_PROJECT_KEY = 'existingProject';
export const EXPIRATION_DELAY = 1;

export const getVariables = (
  extraComment: string,
  agencySlug: string,
  existingProject: ExistingProject,
): MessageBriefingArgs => {
  return {
    memberUuid: existingProject?.memberUuid,
    agencySlug,
    messageArgs: { intent: 'other', content: extraComment },
  };
};

export const isActive = (project?: ExistingProject) => {
  const { expiryDate } = { ...project };
  const active = expiryDate && new Date(expiryDate) > new Date();
  if (expiryDate && !active) removeStorage(EXISTING_PROJECT_KEY);
  return active;
};

export const isExisting = (project?: ExistingProject) => {
  const { memberUuid, encodedProjectUuid } = { ...project };
  return memberUuid && encodedProjectUuid && Boolean(isActive(project));
};

export const getExistingProject = (): ExistingProject | undefined => {
  const data = getStorage(EXISTING_PROJECT_KEY);
  if (isNonEmptyString(data)) {
    const project = JSON.parse(data);
    return isExisting(project) ? project : undefined;
  }
  return undefined;
};

export const getExpiryDate = (): string => {
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + EXPIRATION_DELAY);
  return futureDate.toISOString();
};

export const storeExistingPoject = (project: ExistingProject) => {
  const existingProject = { ...project, expiryDate: getExpiryDate() };
  setStorage(EXISTING_PROJECT_KEY, JSON.stringify(existingProject));
};

export const removeExistingProject = () => {
  removeStorage(EXISTING_PROJECT_KEY);
};
