import {
  extractAbTestsFromCookiesClient,
  FeatureFlagSetup,
  useExtractFeatureFlagOverrides,
} from '@sortlist-frontend/feature-flags';
import { isIgnoredError, Monitor } from '@sortlist-frontend/mlm';
import { PublicApiTrackingEvents, RequiredTrackProps } from '@sortlist-frontend/tracking';
import { getStorage, isNonEmptyPlainObject, isString } from '@sortlist-frontend/utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';

export const urls = {
  track: `/api/proxy/log`,
};

const getSafeJson = (json: string | false | null) => {
  if (!json) return json;

  try {
    return JSON.parse(json);
  } catch {
    return json;
  }
};

export const trackRepo = {
  track: async (
    data: PublicApiTrackingEvents,
    featureFlagsConfig: FeatureFlagSetup,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    const anonymousId = getSafeJson(getStorage('ajs_anonymous_id'));
    const loggedUserId = getSafeJson(getStorage('ajs_user_id'));
    const timestamp = new Date().toISOString();

    const runningExperiments = extractAbTestsFromCookiesClient(featureFlagsConfig);
    const experimentsRunning =
      runningExperiments.gbuuid != null && isNonEmptyPlainObject(runningExperiments.experiments);

    const extraTrackProps: RequiredTrackProps = {
      user_id: isString(loggedUserId) ? loggedUserId : isString(anonymousId) ? anonymousId : '',
      user_type: loggedUserId != null ? 'logged' : anonymousId != null ? 'anonymous' : undefined,
      ab_tests: experimentsRunning ? runningExperiments : {},
      sent_at: timestamp,
      app: 'appPublic',
    };

    const trackResponse = await api.post(
      urls.track,
      {
        data: { attributes: { ...data, ...extraTrackProps } },
      },
      config,
    );

    return trackResponse?.data;
  },
};

export function useTrackWithoutContext(originUrl: string, requestUrl: string) {
  const experimentConfig = useExtractFeatureFlagOverrides({}, requestUrl);
  return {
    mutateAsync: (data: PublicApiTrackingEvents) => trackRepo.track(data, experimentConfig, { baseURL: originUrl }),
  };
}

export function useTrack() {
  const { domainInfo, canonical } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';
  const experimentConfig = useExtractFeatureFlagOverrides({}, canonical);

  return useMutation({
    mutationFn: (data: PublicApiTrackingEvents) => trackRepo.track(data, experimentConfig, { baseURL }),
    onError: (error, variables) => {
      const responseHeaders = (error as any)?.props?.previousError?.response?.headers;
      const previousError = (error as any)?.props?.previousError;

      // Sentry seems to have issues filtering the error at this level, so we do a manual check
      const errorMessage = error.message;
      if (
        (errorMessage != null && isIgnoredError(errorMessage)) ||
        (error as unknown as { status: number }).status == 422
      ) {
        return null;
      }

      Monitor.captureException('useTrack error', {
        extra: { variables, error, responseHeaders, previousError },
      });
    },
  });
}
